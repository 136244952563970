import React from 'react';
import PropTypes from 'prop-types';
import SlideArrow from '../../../public/assets/arrows/slideArrow';

const Arrow = ({ reverse, right, onClick }) => {
  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
  };
  const s = require('./arrow.module.scss');
  return (
    <div onClick={handleClick} className={`slick-arrow ${s.Arrow} ${reverse ? s.Reverse : ''} ${right ? s.Right : ''}`}>
      <SlideArrow />
    </div>
  );
};

Arrow.propTypes = {
  reverse: PropTypes.bool,
  right: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Arrow;
